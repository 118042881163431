import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class HostnameService {
    get HidsAPI() {
        return "https://api.hids.vn";
    }

    get HidsAPI_Readv1() {
        return "https://api.hids.vn/api/v1/read";
        // return 'http://localhost:5000';
    }

    get HidsAPI_Producerv1() {
        return "https://api.hids.vn/api/v1/producer";
        // return 'http://localhost:5001';
    }
}
