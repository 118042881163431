// import { Injectable, OnDestroy, OnInit } from '@angular/core';
// import { MatPaginatorIntl } from '@angular/material/paginator';
// import { TranslateService } from '@ngx-translate/core';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

// @Injectable()
// export class PaginatorIntlService extends MatPaginatorIntl {
//     constructor(private translate: TranslateService) {
//         super();
//     }

//     getRangeLabel = (page: number, pageSize: number, length: number) => {
//         super.itemsPerPageLabel = this.translate.instant('paginator.items_per_page');
//         super.nextPageLabel = this.translate.instant('paginator.next_page');
//         super.previousPageLabel = this.translate.instant('paginator.previous_page');

//         const of_translated = this.translate.instant('paginator.of');
//         if (length === 0 || pageSize === 0) {
//             return '0 ' + of_translated + ' ' + length;
//         }
//         length = Math.max(length, 0);
//         const startIndex = page * pageSize;
//         // If the start index exceeds the list length, do not try and fix the end index to the end.
//         const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
//         return startIndex + 1 + ' - ' + endIndex + ' ' + of_translated + ' ' + length;
//     };
// }

export class PaginatorIntlService extends MatPaginatorIntl {
    // translate: TranslateService;

    constructor(private translate: TranslateService) {
        super();
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        const of = this.translate ? this.translate.instant('paginator.of') : 'of';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    };

    injectTranslateService(translate: TranslateService) {
        this.translate = translate;

        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels() {
        super.itemsPerPageLabel = this.translate.instant('paginator.items_per_page');
        super.nextPageLabel = this.translate.instant('paginator.next_page');
        super.previousPageLabel = this.translate.instant('paginator.previous_page');
        this.changes.next();
    }
}
