import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';

@Injectable({ providedIn: 'root' })
export class NhiemvuDanhMucDataService {
    private _data: Map<string, any[]> = new Map();
    private _dataSub: Subject<Map<string, any[]>> = new Subject();

    public get data(): Map<string, any[]> {
        return this._data;
    }
    public set data(value: Map<string, any[]>) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<Map<string, any[]>> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    getDM(ten_danh_muc: string): any[] {
        let result = this.data.get(ten_danh_muc);
        if (result) return result;
        return [];
    }

    updateDM(ten_danh_muc: string, danh_muc: any[]) {
        this.data.set(ten_danh_muc, danh_muc);
        this.data = new Map(this._data);
    }

    async fetch() {
        let result: any = await this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/nhiemvu/danh-muc/get-all', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();

        if (result.status == 'error') {
            this.data = new Map();
            return this._data;
        }

        this._data = new Map();
        Object.keys(result.danhmucs).forEach((key: string) => {
            let values: any = result.danhmucs[key] || [];
            this._data.set(key, values);
        });
        this._dataSub.next(this._data);

        return this._data;
    }

    async fetchIfNotExists() {
        if (this._data.size > 0) return this._data;
        return await this.fetch();
    }

    async add(data: any) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/nhiemvu/danh-muc/add',
                {
                    data: data,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async edit(danh_muc_id: any, data: any) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/nhiemvu/danh-muc/edit?id=' + danh_muc_id,
                {
                    data: data,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async delete(danh_muc_id: any) {
        return this.httpClient
            .delete<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/nhiemvu/danh-muc/delete?id=' + danh_muc_id,
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async addMultipleByFile(file: File, input_type: string) {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('input_type', String(input_type));

        return this.httpClient
            .post<any>(this.hostnameService.HidsAPI_Producerv1 + '/nhiemvu/danh-muc/add-multiple-by-file', formData, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async deleteMultipleById(danhMucIds: string[]) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Producerv1 + '/nhiemvu/danh-muc/delete-multiple-by-ids',
                {
                    ids: danhMucIds,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async downloadTemplate() {
        return this.httpClient
            .get<any>(this.hostnameService.HidsAPI_Readv1 + '/nhiemvu/danh-muc/download-template', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }
}
