import { GeneralDataService } from '../providers/general-data.service';

export function codeToData(
    generalDataService: GeneralDataService,
    code: string,
    type: string,
    mainKey: string = 'ma',
    displayKey: string = 'ten'
) {
    let row = generalDataService.getDataByCode(code, type, mainKey);
    if (!row || !row[displayKey]) return '';
    return row[displayKey];
}

export function createMapingDLHC(dlhc: any) {
    let result: any = [];
    for (let key of Object.keys(dlhc)) result.push({ ma: key, ten: dlhc[key] });
    result = result.sort((a: any, b: any) => {
        if (a.ma > b.ma) return 1;
        if (a.ma < b.ma) return -1;
        return 0;
    });
    return result;
}

export function getQuanHuyenList(generalDataService: GeneralDataService, ma_tinh_thanh: string) {
    let code_qh_from_code_tt = generalDataService.getByType('code_qh_from_code_tt');
    if (code_qh_from_code_tt[ma_tinh_thanh]) {
        let code_qhs: Set<string> = new Set(code_qh_from_code_tt[ma_tinh_thanh]);
        let dlhc_quan_huyen = generalDataService.getByType('dlhc_quan_huyen');
        return dlhc_quan_huyen.filter((row: any) => code_qhs.has(row.ma));
    }
    return [];
}

export function getPhuongXaList(generalDataService: GeneralDataService, ma_tinh_thanh: string, ma_quan_huyen: string) {
    let ma_tt_qh = 'tt' + ma_tinh_thanh + 'qh' + ma_quan_huyen;
    let code_px_from_code_tt_qh = generalDataService.getByType('code_px_from_code_tt_qh');
    if (code_px_from_code_tt_qh[ma_tt_qh]) {
        let code_pxs: Set<string> = new Set(code_px_from_code_tt_qh[ma_tt_qh]);
        let dlhc_phuong_xa = generalDataService.getByType('dlhc_phuong_xa');
        return dlhc_phuong_xa.filter((row: any) => code_pxs.has(row.ma));
    }
    return [];
}

export function getCodesByParrent(generalDataService: GeneralDataService, parrent_code: string) {
    let don_vi_cong_tac = generalDataService.getByType('don_vi_cong_tac');
    return don_vi_cong_tac.filter((org: any) => org['parrent_code'] == parrent_code);
}
