import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async getPresignded(filename: string, filetype: string, minutes: any) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Readv1 + '/files/get-presigned',
                {
                    filename: filename,
                    filetype: filetype,
                    minutes: minutes,
                },
                {
                    headers: {
                        apikey: this.accountService.getAPIKey(),
                    },
                }
            )
            .toPromise();
    }

    async getStat(filename: string, filetype: string) {
        return this.httpClient
            .post<any>(
                this.hostnameService.HidsAPI_Readv1 + '/files/get-stat',
                {
                    filename: filename,
                    filetype: filetype,
                },
                {
                    headers: {
                        apikey: this.accountService.getAPIKey(),
                    },
                }
            )
            .toPromise();
    }
}
