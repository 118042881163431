import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: 'account',
    name: 'menuitems.tai_khoan',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'account-info', name: 'menuitems.account_info', type: 'link' }],
};

export const MENU_ITEMS_LUUTRU = {
    state: 'storage',
    name: 'menuitems.luu_tru',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'files-downloaded', name: 'menuitems.file_downloaded', type: 'link' }],
};

export const MENU_ITEMS_TIENICH = {
    state: 'utitlities',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'standard-address', name: 'menuitems.standard_address', type: 'link' },
        { state: 'standard-general-data', name: 'menuitems.standard_general_data', type: 'link' },
        { state: 'standard-staff', name: 'menuitems.standard_staff', type: 'link' },
    ],
};

export const MENU_ITEMS_NHIEMVU = {
    state: 'nhiemvu',
    name: 'menuitems.nhiemvu',
    type: 'sub',
    icon: 'work',
    children: [
        { state: 'list', name: 'menuitems.nhiemvu_list', type: 'link' },
        // { state: 'danh-muc', name: 'menuitems.nhiemvu_danh_muc_list', type: 'link' }
    ],
};

export const MENU_ITEMS_DANHMUC = {
    state: 'danhmuc',
    name: 'menuitems.nhiemvu_danh_muc_list',
    type: 'sub',
    icon: 'dataset',
    children: [
        // { state: 'loai_nhiem_vu', name: 'nhiemvu.danh_muc.loai_nhiem_vu', type: 'link' },
        { state: 'chuc_vu', name: 'nhiemvu.danh_muc.lanh_dao_vien_vai_tro', type: 'link' },
        { state: 'trang_thai', name: 'nhiemvu.danh_muc.trang_thai_hien_tai', type: 'link' },
    ],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    [MCODES.nhiemvu, MENU_ITEMS_NHIEMVU],
    [MCODES.danhmuc, MENU_ITEMS_DANHMUC],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    MCODES.nhiemvu,
    MCODES.danhmuc,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.taikhoan,
];
