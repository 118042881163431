import { Component, OnDestroy } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/providers/language.service';
import { AccountService } from 'src/app/providers/account.service';
import { KeycloakService } from 'keycloak-angular';
import { AccountAvatarService } from 'src/app/providers/avatar.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-vertical-header',
    templateUrl: './vertical-header.component.html',
    styleUrls: [],
})
export class VerticalAppHeaderComponent implements OnDestroy {
    // Translate
    private languageSub!: Subscription;

    public config: PerfectScrollbarConfigInterface = {};
    public selectedLanguage: any = this.languageService.SupportLanguage[1];
    public avatarLink: string = '';

    constructor(
        private translate: TranslateService,
        private languageService: LanguageService,
        private keycloakService: KeycloakService,
        private accountAvatarService: AccountAvatarService,
        private router: Router
    ) {
        this.updateByLanguage();
        this.languageSub = this.languageService.languageSub.subscribe((newLanguage: string) => {
            this.updateByLanguage();
        });

        // translate.setDefaultLang('vn');
        // this.changeLanguage("vn");

        if (this.accountAvatarService.AvatarLink == '') this.avatarLink = '/assets/images/users/default.png';
        else this.avatarLink = this.accountAvatarService.AvatarLink;

        this.accountAvatarService.AvatarLinkSub.subscribe((value: string) => {
            if (value == '') this.avatarLink = './assets/images/users/default.png';
            else this.avatarLink = value;
        });
    }

    async ngAfterViewInit() {
        await this.accountAvatarService.updateAvatarLink();
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
    }

    get languages() {
        return this.languageService.SupportLanguage;
    }

    changeLanguage(lang: any): void {
        this.translate.use(lang.code);
        this.selectedLanguage = lang;
        this.languageService.language = lang.code;
    }

    logout() {
        this.keycloakService.logout();
    }

    accountInfo() {
        this.router.navigateByUrl('/account/account-info');
    }

    updateByLanguage() {
        this.translate.use(this.languageService.language);
        this.selectedLanguage = this.languageService.SupportLanguage.find(
            (lang: any) => lang.code == this.languageService.language
        );
        if (!this.selectedLanguage) this.selectedLanguage = this.languageService.SupportLanguage[1];
    }
}
