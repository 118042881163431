import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Menu } from 'src/app/interface/menu-items';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { MENU_ITEMS, MENU_ITEMS_ORDER } from './menu-items.service.utils';
import { MCODES } from './roles.menu.utils';

@Injectable({ providedIn: 'root' })
export class MenuItems {
    private _menuItems: Menu[] = [];
    private _menuItemsSub: Subject<Menu[]> = new Subject();

    get Menuitems() {
        return this._menuItems;
    }
    set Menuitems(input: Menu[]) {
        this._menuItems = input;
        this._menuItemsSub.next(this._menuItems);
    }

    get MenuitemsSub() {
        return this._menuItemsSub;
    }

    constructor(private accountService: AccountService, private hostnameService: HostnameService) {}

    updateDisplay(items: string[]) {
        let ordered_items = MENU_ITEMS_ORDER.filter((item: string) => items.includes(item));
        let menu_items: any[] = [];
        for (let item of ordered_items) {
            menu_items.push(MENU_ITEMS.get(item));
        }

        this.Menuitems = menu_items;
    }
}
