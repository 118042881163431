import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const SUPPORT_LANGUAGES = [
    {
        language: 'English',
        code: 'en',
        type: 'US',
        icon: 'us',
    },
    {
        language: 'Tiếng Việt',
        code: 'vn',
        type: 'VN',
        icon: 'vn',
    },
];

@Injectable({ providedIn: 'root' })
export class LanguageService {
    private _language: string = 'vn';
    private _languageSub: Subject<string> = new Subject();

    get SupportLanguage() {
        return SUPPORT_LANGUAGES;
    }

    public get language(): string {
        return this._language;
    }
    public set language(value: string) {
        this._language = value;
        this._languageSub.next(this._language);
    }
    public get languageSub(): Subject<string> {
        return this._languageSub;
    }
}
